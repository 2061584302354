import {
  LandingPageDestination,
  LandingPageDestinationType,
  LandingPageFormValuesType
} from '@dtx-company/inter-app/src/types/flowcode'
import { TWITTER_LABEL } from '@app/code/src/components/molecules/destination-icon'
import { flowpageLogo, googleLogo } from './assets'

// TODO: replace svg with png
// alt, gaId, default destination
export const LANDING_PAGE_DESTINATIONS: LandingPageDestinationType[] = [
  {
    label: 'Website',
    id: LandingPageDestination.URL,
    src: '/images/landing-page-destinations/website.svg',
    alt: 'website',
    inputLabel: 'Enter or Paste a link',
    placeholder: 'www.mywebsite.com'
  },
  {
    label: 'File',
    id: LandingPageDestination.FILE,
    src: '/images/landing-page-destinations/file.svg',
    alt: 'link to a File'
  },
  {
    label: 'YouTube',
    id: LandingPageDestination.YOUTUBE,
    src: '/images/landing-page-destinations/youtube.png',
    alt: 'youtube',
    inputLabel: 'Enter a link to your Youtube page or video',
    placeholder: 'www.youtube.com/c/WillSmith'
  },
  {
    label: 'Google Doc',
    id: LandingPageDestination.GOOGLE_DOC,
    src: googleLogo,
    alt: 'Google Doc',
    inputLabel: 'Enter or paste a link to your Google Doc',
    placeholder: 'https://docs.google.com/document'
  },
  {
    label: 'Instagram',
    id: LandingPageDestination.INSTAGRAM,
    src: '/images/landing-page-destinations/instagram.svg',
    alt: 'instagram',
    inputLabel: 'Enter a link to your Instagram profile or post',
    placeholder: 'instagram.com/myusername'
  },
  {
    label: 'Facebook',
    id: LandingPageDestination.FACEBOOK,
    src: '/images/landing-page-destinations/facebook.svg',
    alt: 'facebook',
    inputLabel: 'Enter a link to your Facebook profile or post',
    placeholder: 'facebook.com/GetFlowcode'
  },
  {
    label: 'SMS',
    id: LandingPageDestination.SMS,
    src: '/images/landing-page-destinations/sms.svg',
    alt: 'sms'
  },
  {
    label: 'Email',
    id: LandingPageDestination.EMAIL,
    src: '/images/landing-page-destinations/email.svg',
    alt: 'email'
  },
  {
    label: 'WiFi',
    id: LandingPageDestination.WIFI,
    src: '/images/landing-page-destinations/wifi.svg',
    alt: 'wifi'
  },
  {
    label: 'Venmo',
    id: LandingPageDestination.VENMO,
    src: '/images/landing-page-destinations/venmo.svg',
    alt: 'venmo'
  },
  {
    label: 'Phone call',
    id: LandingPageDestination.PHONE,
    src: '/images/landing-page-destinations/phone.svg',
    alt: 'phone call'
  },
  {
    label: 'LinkedIn',
    id: LandingPageDestination.LINKEDIN,
    src: '/images/landing-page-destinations/linkedin.svg',
    alt: 'linkedin',
    inputLabel: 'Enter a link to your LinkedIn profile or post',
    placeholder: 'www.linkedin.com/in/erik-hanson/'
  },
  {
    label: 'TikTok',
    id: LandingPageDestination.TIKTOK,
    src: '/images/landing-page-destinations/tiktok.svg',
    alt: 'tiktok',
    inputLabel: 'Enter a link to your TikTok profile or post',
    placeholder: 'tiktok.com/@myusername/'
  },
  {
    label: 'Snapchat',
    id: LandingPageDestination.SNAPCHAT,
    src: '/images/landing-page-destinations/snapchat.svg',
    alt: 'snapchat',
    inputLabel: 'Enter a link to your Snapchat username',
    placeholder: 'snapchat.com/add/username'
  },
  {
    label: TWITTER_LABEL,
    id: LandingPageDestination.TWITTER,
    src: '/icons/social-media/twitter.svg',
    alt: TWITTER_LABEL,
    inputLabel: `Enter a link to your ${TWITTER_LABEL} profile or tweet`,
    placeholder: 'https://twitter.com/myusername'
  },
  {
    label: 'Spotify',
    id: LandingPageDestination.SPOTIFY,
    src: '/images/landing-page-destinations/spotify.svg',
    alt: 'spotify',
    inputLabel: 'Enter a link to your Spotify profile or playlist',
    placeholder: 'open.spotify.com/genre/rnb-page'
  },
  { label: 'Flowpage', id: LandingPageDestination.FLOWPAGE, src: flowpageLogo, alt: 'Flowpage' },
  {
    label: 'vCard',
    id: LandingPageDestination.VCARD,
    src: '/images/landing-page-destinations/vcard.svg',
    alt: 'vCard'
  },
  {
    label: 'Scan to Own',
    id: LandingPageDestination.SCAN_TO_OWN,
    src: '/images/landing-page-destinations/flowcode.svg',
    alt: 'scanToOwn'
  }
]

export const LANDING_PAGE_DESTINATIONS_MAP: Record<
  LandingPageDestination,
  LandingPageDestinationType
> = LANDING_PAGE_DESTINATIONS.reduce((acc, cur) => {
  acc[cur.id] = cur
  return acc
}, {} as Record<LandingPageDestination, LandingPageDestinationType>)

export const SOCIAL_MEDIA_DESTINATIONS: LandingPageDestinationType[] = [
  {
    label: 'Facebook',
    id: LandingPageDestination.FACEBOOK,
    src: '',
    alt: 'facebook',
    inputLabel: 'Enter the link to your Facebook profile or post',
    prefix: 'https://facebook.com/',
    placeholder: 'facebook.com/GetFlowcode',
    alfredIcon: true
  },
  {
    label: 'Instagram',
    id: LandingPageDestination.INSTAGRAM,
    src: '',
    alt: 'instagram',
    inputLabel: 'Enter your Instagram handle',
    prefix: 'https://instagram.com/',
    alfredIcon: true
  },
  {
    label: 'LinkedIn',
    id: LandingPageDestination.LINKEDIN,
    src: '/images/landing-page-destinations/linkedin.svg',
    alt: 'linkedin',
    inputLabel: 'Enter the link to your LinkedIn profile or post',
    placeholder: 'www.linkedin.com/in/erik-hanson/',
    alfredIcon: true
  },
  {
    label: 'TikTok',
    id: LandingPageDestination.TIKTOK,
    src: '',
    alt: 'tiktok',
    inputLabel: 'Enter your TikTok handle',
    prefix: 'https://www.tiktok.com/@',
    alfredIcon: true
  },
  {
    label: 'Snapchat',
    id: LandingPageDestination.SNAPCHAT,
    src: '',
    alt: 'snapchat',
    inputLabel: 'Enter your Snapchat username',
    prefix: 'https://www.snapchat.com/add/',
    alfredIcon: true
  },
  {
    label: TWITTER_LABEL,
    id: LandingPageDestination.TWITTER,
    src: '',
    alt: TWITTER_LABEL,
    inputLabel: `Enter your ${TWITTER_LABEL} Handle`,
    prefix: 'https://twitter.com/',
    alfredIcon: true
  }
]

export const LANDING_PAGE_DESTINATIONS_SORTED: LandingPageDestinationType[] = [
  {
    label: 'Website',
    id: LandingPageDestination.URL,
    src: '',
    alt: 'website',
    inputLabel: 'Enter or Paste a link',
    placeholder: 'www.mywebsite.com',
    alfredIcon: true
  },
  {
    label: 'YouTube',
    id: LandingPageDestination.YOUTUBE,
    src: '',
    alt: 'youtube',
    inputLabel: 'Enter a link to your Youtube page or video',
    placeholder: 'www.youtube.com/c/WillSmith',
    alfredIcon: true
  },
  {
    label: 'Facebook',
    id: LandingPageDestination.FACEBOOK,
    src: '',
    alt: 'facebook',
    inputLabel: 'Enter the link to your Facebook profile or post',
    placeholder: 'facebook.com/GetFlowcode',
    alfredIcon: true
  },
  {
    label: 'Instagram',
    id: LandingPageDestination.INSTAGRAM,
    src: '',
    alt: 'instagram',
    alfredIcon: true
  },
  {
    label: 'Text message',
    id: LandingPageDestination.SMS,
    src: '',
    alt: 'sms',
    alfredIcon: true
  },
  {
    label: 'Call',
    id: LandingPageDestination.PHONE,
    src: '',
    alt: 'phone call',
    alfredIcon: true
  },
  {
    label: 'TikTok',
    id: LandingPageDestination.TIKTOK,
    src: '',
    alt: 'tiktok',
    alfredIcon: true
  },
  {
    label: TWITTER_LABEL,
    id: LandingPageDestination.TWITTER,
    src: '',
    alt: TWITTER_LABEL,
    alfredIcon: true
  },
  {
    label: 'Snapchat',
    id: LandingPageDestination.SNAPCHAT,
    src: '',
    alt: 'snapchat',
    alfredIcon: true
  },
  {
    label: 'LinkedIn',
    id: LandingPageDestination.LINKEDIN,
    src: '',
    alt: 'LinkedIn',
    alfredIcon: true
  },
  {
    label: 'Email',
    id: LandingPageDestination.EMAIL,
    src: '',
    alt: 'email',
    alfredIcon: true
  },
  {
    label: 'Google Doc',
    id: LandingPageDestination.GOOGLE_DOC,
    src: '',
    alt: 'Google Doc',
    inputLabel: 'Enter or paste a link to your Google Doc',
    placeholder: 'https://docs.google.com/document',
    alfredIcon: true
  },
  {
    label: 'Upload a file',
    id: LandingPageDestination.FILE,
    src: '',
    alt: 'link to a File',
    inputLabel: 'Upload a pdf, image, video, or audio file from your device',
    placeholder: 'myfile.jpg',
    alfredIcon: true
  },
  {
    label: 'Image',
    id: LandingPageDestination.IMAGE,
    src: '',
    alt: 'link to an Image',
    inputLabel: 'Upload an image file (png, jpeg, etc.) from your device',
    placeholder: 'myfile.png',
    alfredIcon: true
  },
  {
    label: 'Venmo',
    id: LandingPageDestination.VENMO,
    src: '',
    alt: 'venmo',
    alfredIcon: true
  },
  {
    label: 'WiFi',
    id: LandingPageDestination.WIFI,
    src: '',
    alt: 'wifi',
    alfredIcon: true
  }
]

export const LANDING_PAGE_FORM_INITIAL_VALUES: LandingPageFormValuesType = {
  link: '',
  sms: {
    message: '',
    phoneNumber: ''
  },
  phone: '',
  email: {
    email: '',
    subject: '',
    body: ''
  },
  venmo: {
    amount: undefined,
    recipients: '@',
    note: ''
  },
  file: null,
  event: {
    name: '',
    startTime: '',
    url: '',
    location: '',
    endTime: ''
  },
  wifi: {
    ssid: '',
    authenticationType: 'nopass',
    password: '',
    hidden: ''
  },
  vcard: {
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    title: undefined,
    email: undefined,
    phone: undefined,
    phone_country_code: undefined,
    work_phone: undefined,
    work_phone_country_code: undefined,
    website: undefined,
    street: undefined,
    street2: undefined,
    city: undefined,
    state: undefined,
    zipcode: undefined,
    country: undefined
  }
}
