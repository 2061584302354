export const posterTemplateIcon = '/images/post-download/poster-icon.png'
export const tableTentTemplateIcon = '/images/post-download/table-tent-icon.png'
export const postCardTemplateIcon = '/images/post-download/post-card-icon.png'

export const curvedBottomRightArrow = '/icons/CurvedBottomRightArrow.svg'

export const alipay = '/static/images/account/alipay.png'
export const bancontact = '/static/images/account/bancontact.png'
export const bank = '/static/images/account/bank.png'
export const blik = '/static/images/account/blik.png'
export const boleto = '/static/images/account/boleto.png'
export const cardAmex = '/static/images/account/amex.png'
export const cardApplePay = '/static/images/account/applepay.png'
export const cardDefault = '/static/images/account/default.svg'
export const cardDiners = '/static/images/account/diners.svg'
export const cardDiscover = '/static/images/account/discover.png'
export const cardGooglePay = '/static/images/account/googlepay.png'
export const cardJcb = '/static/images/account/jcb.png'
export const cardMasterCard = '/static/images/account/mastercard.png'
export const cardUnionPay = '/static/images/account/unionpay.svg'
export const cardVisa = '/static/images/account/visa.png'
export const cashapp = '/static/images/account/cashapp.png'
export const defaultPaymentMethod = '/static/images/account/defaultPaymentMethod.png'
export const eps = '/static/images/account/EPS.png'
export const grabPay = '/static/images/account/grabPay.png'
export const ideal = '/static/images/account/ideal.png'
export const mobilePay = '/static/images/account/mobilePay.png'
export const sepa = '/static/images/account/sepa.png'
export const weChatPay = '/static/images/account/weChatPay.png'

export const checkmarkFlowBlue = '/icons/checkmark-flow-blue.svg'

export const flowcodeGeneric = '/static/images/flowcodes/code-generic.png'
export const flowcodePreviewAndes = '/images/homepage-flowcode-andes.png'
export const flowcodePreviewCustomizeBanner = '/graphics/customize-codes-banner.png'
export const flowcodePreviewEmail = '/images/homepage-flowcode-email.png'
export const flowcodePreviewFaces = '/images/homepage-flowcode-faces.png'
export const flowcodePreviewVenmo = '/images/homepage-flowcode-venmo.png'
export const flowpageLogo = '/icons/flowpage-logo-black.svg'

export const globeOutline = '/icons/globe-outline.svg'
export const googleLogo = '/logos/google.svg'

export const amexBanner = '/images/amex-banner.svg'

export const flowcodeGeneral = '/icons/product/flowcode-general.svg'

export const circlePlusOutline = '/icons/circle-plus-outline.svg'
export const circlePlusOutlineWhite = '/icons/circle-plus-outline-white.svg'
export const flowcodeRectangleLogoBlack = '/icons/flowcode-small-black.svg'

export const scanToOwnGenericLandingBackground = '/images/ScanToOwn/LandingBackground.png'
export const scanToOwnGenericWebsiteFormBanner = '/images/ScanToOwn/WebsiteFormBanner.png'
export const scanToOwnGenericTextMsgFormBanner = '/images/ScanToOwn/TextMsgFormBanner.png'
export const scanToOwnGenericEmailFormBanner = '/images/ScanToOwn/EmailFormBanner.png'
export const scanToOwnGenericSocialFormBanner = '/images/ScanToOwn/SocialFormBanner.png'
export const scanToOwnGenericGoogleDocFormBanner = '/images/ScanToOwn/GoogleDocFormBanner.png'
export const scanToOwnGenericFileFormBanner = '/images/ScanToOwn/FileFormBanner.png'
export const scanToOwnGenericYoutubeFormBanner = '/images/ScanToOwn/YoutubeFormBanner.png'
export const scanToOwnGenericPhoneNumberFormBanner = '/images/ScanToOwn/PhoneNumberFormBanner.png'
export const scanToOwnGenericVenmoFormBanner = '/images/ScanToOwn/VenmoFormBanner.png'
export const scanToOwnGenericFlowpagePreview = '/images/ScanToOwn/FlowpagePreview.png'

export const qr = '/icons/Qr.svg'
export const pageOutlined = '/icons/PageOutlined.svg'
export const dataHorizontalBarOutlined = '/icons/DataHorizontalBar.svg'
export const errorFlowcode = '/static/images/flowcodes/ErrorFlowcode.png'
export const errorFlowcodeBlack = '/static/images/flowcodes/ErrorFlowcodeBlack.png'

export const smartLinksIcon = '/icons/smart-links.svg'
export const myFlowpagesIconFilledBlack = '/icons/my-flowpages-filled-black.svg'
export const lightningBoltIconFilled = '/icons/lightning-bolt-filled.svg'
export const lockIconBlackBackground = '/icons/lock-black-background.svg'
