import { gql } from 'graphql-request'

export const FOLDER_ANCESTORS_FRAGMENT = gql`
  fragment FolderAncestors on DirectoryType {
    id
    name
    batchCount
  }
`

export const CUSTOM_ASSIGNED_CODES_FRAGMENT = gql`
  fragment CustomAssignedCodes on StudioConfigObjectType {
    id
    name
    configuration
    assetFile {
      assetName
      url
    }
    isCustomizable
  }
`

export const FLOWCODE_TEMPLATES_FRAGMENT = gql`
  fragment FlowcodeTemplates on StudioConfigObjectType {
    id
    name
    configuration
    assetFile {
      assetName
      url
    }
    isCustomizable
  }
`

export const BATCH_TEMPLATES_FRAGMENT = gql`
  fragment BatchTemplates on BatchDesignTemplateObjectType {
    id
    configuration
    name
  }
`

export const BORDER_DESIGN_CONFIGS_FRAGMENT = gql`
  fragment BorderDesignConfigs on BorderDesignObjectType {
    studioConfig {
      configuration
      assetFile {
        assetName
        url
      }
      name
      id
    }
    partialId
  }
`
