export const linkTypes = [
  {
    provider: 'instagram',
    type: 'profile',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?instagram\.com\/(?<title>[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)\/?$/
    // https://instagram.com/richprze
  },
  {
    provider: 'instagram',
    type: 'post',
    pattern: /^(http(s)?:\/\/)?(www\.)?instagram\.com\/p\/(?<id>[^/?#&]+).*/
    //https://www.instagram.com/p/Bda2mUzFFQkbkv5_2WkybnYfLr51Sn4bhMk23M0/
  },
  {
    provider: 'facebook',
    type: 'post',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?(?<id>facebook|fb)\.com\/(photo.php|permalink.php|media|questions|notes)(\?)?.*$/
    // https://www.facebook.com/photo.php?fbid=10111797917006541&set=a.10100268672395161&type=3
  },
  {
    provider: 'facebook',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?(facebook|fb)\.com\/(?<title>[A-z0-9_-]+)\/?/
    // https://www.facebook.com/przekop
  }, // facebook posts are annoying because of mobile vs desktop... will do later
  {
    provider: 'facebook',
    type: 'profile',
    pattern: /^(fb:\/\/(profile|page)\/)(?<id>[A-z0-9_-]+)\/?/
    // fb://profile/443508996396952 - use profile for iOS
    // fb://page/443508996396952 - use page for Android
  },
  {
    provider: 'facebook',
    type: 'video',
    embeddable: true,
    pattern:
      /^(http(s)?:\/\/)?(www\.)?(facebook|fb)\.com\/(?<title>[A-z0-9_]+)\/videos\/(?<id>[A-z 0-9 _ -]+)\/?.*$/
    // https://www.facebook.com/drdrulia/videos/276841033562322/
  },
  {
    provider: 'facebook',
    type: 'link',
    pattern: /^(http(s)?:\/\/)?(www\.)?facebook\.com?.*$/
    // facebook.com
  },
  {
    provider: 'twitter',
    type: 'post',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?twitter|x\.com\/(?<title>[A-z0-9_]+)\/(status)\/(?<id>[A-z0-9_]+)\/?/
    // https://twitter.com/KimKardashian/status/1250134713395744768
    // https://x.com/ScienceNews/status/1565445141741142017
  },
  {
    provider: 'twitter',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?twitter|x\.com\/(?<title>[A-z0-9_]+)\/?/
    // https://twitter.com/kimkardashian
    // https://x.com/ScienceNews
  },
  {
    provider: 'tiktok',
    type: 'post',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?tiktok\.com\/@(?<title>[A-z0-9_-]+)\/(video)\/(?<id>[A-z0-9_]+)\/?/
    // https://www.tiktok.com/@steveharvey/video/6801295458112261381
  },
  {
    provider: 'tiktok',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?tiktok\.com\/@(?<title>[A-z0-9_-]+)\/?/
    // https://www.tiktok.com/@steveharvey
  },
  {
    provider: 'linkedin',
    type: 'profile',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/(in|profile|pub|school|company)\/(?<title>[A-z0-9_-]+)\.?\/?$/
    // https://www.linkedin.com/in/richprzekop/
  },
  {
    provider: 'linkedin',
    type: 'post',
    pattern: /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/(feed)\/(?<id>[A-z0-9_-]+)\/?.*$/
    // https://www.linkedin.com/feed/update/urn:li:activity:6653712994503057410/
  },
  {
    provider: 'soundcloud',
    type: 'song',
    embeddable: true,
    pattern:
      /^(http(s)?:\/\/)?(www\.)?soundcloud\.com\/(?<title>[A-z0-9_-]+)\/(?<id>[A-z0-9_-]+)\/?.*$/
    // https://soundcloud.com/roddyricch/the-box
  },
  {
    provider: 'soundcloud',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?soundcloud\.com\/(?<title>[A-z0-9_-]+)\/?.*$/
    //https://soundcloud.com/roddyricch/
  },
  {
    provider: 'pinterest',
    type: 'collection',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?pinterest\.com\/(?<title>[A-z0-9_-]+)\/(?<collection>[A-z0-9_-]+)\/?.*$/
    // https://www.pinterest.com/realduncanhines/back-to-school/
  },
  {
    provider: 'pinterest',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?pinterest\.com\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://www.pinterest.com/realduncanhines/
  },
  {
    provider: 'pinterest',
    type: 'link',
    pattern: /^(http(s)?:\/\/)?(www\.)?pinterest\.com?.*$/
    // pinterest.com
  },
  {
    provider: 'tumblr',
    type: 'post',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?(?<board>[A-z0-9_-]+)\.tumblr\.com\/(post)\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://ras-el-hanout.tumblr.com/post/611214719135760384/des-dessins-pour-un-projet-qui-na-pas-%C3%A9t%C3%A9-retenu
  },
  {
    provider: 'tumblr',
    type: 'board',
    pattern: /^(http(s)?:\/\/)?(www\.)?(?<title>[A-z0-9_-]+)\.tumblr\.com\/?.*$/
    // https://siriuslymeg.tumblr.com/
  },
  {
    provider: 'vimeo',
    type: 'video',
    embeddable: true,
    pattern: /^(http(s)?:\/\/)?(www\.)?vimeo\.com\/(?<id>[0-9]+)\/?.*$/
    //https://vimeo.com/22693608
  },
  {
    provider: 'vimeo',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?vimeo\.com\/(?<title>[A-z _ -]+)\/?.*$/
    // https://vimeo.com/johnnykelly
  },
  {
    provider: 'apple-podcast',
    type: 'podcast',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?podcasts\.apple\.com\/([A-z0-9_-]+)\/podcast\/(?<title>[A-z0-9_-]+)\/id[0-9]+\/?$/
    // https://podcasts.apple.com/ca/podcast/office-ladies/id1480311435
  },
  {
    provider: 'apple-podcast',
    type: 'episode',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?podcasts\.apple\.com\/[A-z0-9_-]+\/podcast\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://podcasts.apple.com/ca/podcast/dwights-speech/id1480311435?i=1000470838654
  },
  {
    provider: 'dailymotion',
    type: 'video',
    pattern: /^(http(s)?:\/\/)?(www\.)?dailymotion\.com\/video\/(?<id>[A-z0-9_-]+)\/?.*$/
    // https://www.dailymotion.com/video/x7t97rs?playlist=x6m762
  },
  {
    provider: 'dailymotion',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?dailymotion\.com\/(?<title>[A-z0-9_-]+)\/?.*$/
    //https://www.dailymotion.com/lifetime
  },
  {
    provider: 'reddit',
    type: 'user',
    pattern: /^(http(s)?:\/\/)?(www\.)?reddit\.com\/user\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://www.reddit.com/user/phasers_to_stun/
  },
  {
    provider: 'reddit',
    type: 'subreddit',
    pattern: /^(http(s)?:\/\/)?(www\.)?reddit\.com\/r\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://www.reddit.com/r/IAmA/comments/g182qf/hi_reddit_im_the_founder_of_quarantine_penpals_a/
  },
  {
    provider: 'ebay',
    type: 'product',
    pattern: /^(http(s)?:\/\/)?(www\.)?ebay\.com\/itm\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://www.ebay.com/itm/BCP-38in-Beginner-Acoustic-Guitar-Musical-Instrument-Kit-w-Case-Strap-Tuner/312316565552?_trkparms=pageci%3A88229665-7ec0-11ea-8b5b-74dbd1800c3a%7Cparentrq%3A7ba8df661710abc19bf8a1c9ffe2c506%7Ciid%3A1
  },
  {
    provider: 'quora',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?quora\.com\/profile\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://www.quora.com/profile/Adrian-Twarog
  },
  {
    provider: 'quora',
    type: 'post',
    pattern: /^(http(s)?:\/\/)?(www\.)?quora\.com\/(?<title>[A-z0-9_-]+)\/?.*$/
    // https://www.quora.com/What-is-the-best-in-2019-React-vs-Angular
  },
  {
    provider: 'youtube',
    type: 'video',
    embeddable: true,
    pattern:
      /^(http(s)?:\/\/)?(www\.)?(m\.)?youtube.com\/watch\?.*(\b|&)v=(?<id>[A-Za-z0-9_-]+)(\b|&).*$/i
  },
  {
    provider: 'youtube',
    type: 'video',
    embeddable: true,
    pattern: /^(http(s)?:\/\/)?(www\.)?(youtu.be|yt.be)\/(?<id>[A-z0-9_-]+)\/?.*$/i
  },
  {
    provider: 'youtube',
    type: 'channel',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?(m.youtube.com|youtube.com)\/(user|channel\/)?(?<id>[A-z0-9_-]+)\/?.*$/i
  },
  {
    provider: 'snapchat',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?snapchat\.com\/(add)\/(?<title>[A-z0-9_-]+)\/?$/
    // https://www.snapchat.com/add/emily
  },
  {
    provider: 'spotify',
    type: 'song',
    embeddable: true,
    // widgetType: 'embedSpotify',
    pattern: /^(http(s)?:\/\/)?(www\.)?open.spotify\.com\/track\/(?<id>[A-z0-9_-]+)?.*$/
    // https://open.spotify.com/track/1vvryLZy7A85rmPbxHYERY
  },
  {
    provider: 'spotify',
    type: 'podcast',
    // widgetType: 'embedSpotify',
    pattern: /^(http(s)?:\/\/)?(www\.)?open.spotify\.com\/show\/(?<id>[A-z0-9_-]+)?.*$/
    // https://open.spotify.com/show/3IM0lmZxpFAY7CwMuv9H4g?si=SHwdOBU4R5KYCJKcu-z21Q
  },
  {
    provider: 'spotify',
    type: 'podcastEpisode',
    embeddable: true,
    // widgetType: 'embedSpotify',
    pattern: /^(http(s)?:\/\/)?(www\.)?open.spotify\.com\/episode\/(?<id>[A-z0-9_-]+)?.*$/
    //https://open.spotify.com/episode/4yHeBXAf5Yu39yiCVyjIxT?si=zyssWCqMSGaLTED1qSqT4w
  },

  {
    provider: 'spotify',
    type: 'artist',
    pattern: /^(http(s)?:\/\/)?(www\.)?open.spotify\.com\/artist\/(?<id>[A-z0-9_-]+)?.*$/
    // https://open.spotify.com/artist/01pKrlgPJhm5dB4lneYAqS
  },
  {
    provider: 'spotify',
    type: 'album',
    embeddable: true,
    pattern: /^(http(s)?:\/\/)?(www\.)?open.spotify\.com\/album\/(?<id>[A-z0-9_-]+)?.*$/
    // https://open.spotify.com/album/44OSVbfmYeai7d1Euvqwpx?highlight=spotify:track:3R2WmuTxsZX5HkGodyQuXx
  },
  {
    provider: 'spotify',
    type: 'playlist',
    embeddable: true,
    pattern: /^(http(s)?:\/\/)?(www\.)?open.spotify\.com\/playlist\/(?<id>[A-z0-9_-]+)?.*$/
    // https://open.spotify.com/playlist/3bxDlGm17K9AyIqwznDSci?si=u3BjvOfVSFChYlGOXgi_zw
  },
  {
    provider: 'venmo',
    type: 'payment',
    pattern:
      /^(venmo:\/\/paycharge\?)[A-z0-9&=,_-]*(recipients=(?<id>[A-z0-9,_-]+))[A-z0-9&=,_-]*\/?/
    // venmo://paycharge?txn=pay&recipients=Alex-Beals&amount=10&note=Note
    // link must include paycharge param to be recognized
  },
  {
    provider: 'venmo',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?venmo.com\/(i\/)?(code\?user_id=)?(?<id>[A-z0-9_-]+)$/
    // https://venmo.com/nikhilramesh - opens app if link clicked on mobile
    // https://venmo.com/code?user_id=2993194040033280487
    // https://venmo.com/i/techguy2000 -- not 100% sure what this is, probably affiliate link
  },
  {
    provider: 'twitch',
    type: 'stream',
    embeddable: true,
    pattern: /^(http(s)?:\/\/)?((www|m)\.)?(player\.)?twitch\.tv\/(?<id>[A-z0-9_-]+)?(\?.*)?$/
    /*
      https://www.twitch.tv/gmhikaru
      https://m.twitch.tv/apfns
      ❌ https://twitch.tv/ttv_fluffy43/
      https://twitch.tv/ttv_fluffy43?utm=blah
      ❌ https://m.twitch.tv/itsjumanjee/profile -- goes to videos page -- not embedable
      ❌ https://twitch.tv/ttv_fluffy43/profile
    */
  },
  {
    provider: 'twitch',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?((www|m)\.)?twitch\.tv\/(?<id>[A-z0-9_-]+)\/profile$/
    /*
      ❌ https://www.twitch.tv/gmhikaru
      ❌ https://m.twitch.tv/apfns
      ❌ https://twitch.tv/ttv_fluffy43/
      ❌ https://twitch.tv/ttv_fluffy43?utm=blah
      https://m.twitch.tv/itsjumanjee/profile -- goes to videos page -- not embedable
      https://twitch.tv/ttv_fluffy43/profile
    */
  },
  {
    provider: 'streamlabs',
    type: 'payment',
    pattern: /^(http(s)?:\/\/)?(www\.)?streamlabs.com\/(?<id>[A-z0-9_-]+)(\/tip)?/
    // most popular streaming donation platform, would pair well with twitch embedding
    // https://streamlabs.com/ninja - this redirects to the /tip URL
    // https://streamlabs.com/ninja/tip
  },
  {
    provider: 'doordash',
    type: 'restaurant',
    pattern: /^(http(s)?:\/\/)?(www\.)?doordash\.com\/store\/(?<id>[A-z0-9_-]+)?.*$/
    // https://www.doordash.com/store/shake-shack-new-york-774789/en-US
  },
  {
    provider: 'ubereats',
    type: 'restaurant',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?ubereats\.com\/[A-z_-]+\/food-delivery\/(?<id>[A-z0-9_-]+)?.*$/
    // https://www.ubereats.com/nashville/food-delivery/chipotle-mexican-grill-430-cool-springs-blvd-ste-100/XZnufnYDQZyeEa8g-P23cg
    // https://www.ubereats.com/new-york/food-delivery/a-taste-of-seafood-express-harlem/uRtvzIuRTsG0HAp-UYpBYA
  },
  {
    provider: 'caviar',
    type: 'restaurant',
    pattern: /^(http(s)?:\/\/)?(www\.)?trycaviar\.com\/m\/(?<id>[A-z0-9_-]+)?.*$/
    // https://www.trycaviar.com/m/shake-shack--flatbush-ave-5538
  },
  {
    provider: 'grubhub',
    type: 'restaurant',
    pattern: /^(http(s)?:\/\/)?(www\.)?grubhub\.com\/restaurant\/(?<id>[A-z0-9_-]+)?.*$/
    // https://www.grubhub.com/restaurant/community-food--juice-2893-broadway-new-york/2168893
  },
  {
    provider: 'postmates',
    type: 'restaurant',
    pattern: /^(http(s)?:\/\/)?(www\.)?postmates\.com\/merchant\/(?<id>[A-z0-9_-]+)?.*$/
    // https://postmates.com/merchant/popeyes-louisiana-kitchen-new-york-321-w-125th-st
  },
  {
    provider: 'gofundme',
    type: 'donation',
    pattern:
      /^(http(s)?:\/\/)?(www\.)?gofundme\.com\/f\/(?<id>[A-z0-9_-]+)(\/?(donate|share|donations)?\/?)?$/
    // www.gofundme.com/f/official-memorial-fund-for-secoriea-turner/
    // www.gofundme.com/f/official-memorial-fund-for-secoriea-turner/donate/
    // www.gofundme.com/f/official-memorial-fund-for-secoriea-turner/share/
    // www.gofundme.com/f/official-memorial-fund-for-secoriea-turner/donations/
  },
  {
    provider: 'onlyfans',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?onlyfans\.com\/(?<id>[^/?#&]+).*/
    //https://www.onlyfans.com/luiscapecchi
  },
  {
    provider: 'patreon',
    type: 'profile',
    pattern: /^(http(s)?:\/\/)?(www\.)?patreon\.com\/(?<id>[^/?#&]+).*/
    //https://www.patreon.com/gaslit
  },
  {
    provider: 'vidyard',
    type: 'video',
    embeddable: true,
    pattern: /^(http(s)?:\/\/)?(share\.)vidyard\.com\/watch\/(?<id>.*)\/?.*$/
    // https://share.vidyard.com/watch/3YuPDxkXQFpnMEVgu6bYLF?ig=90909
  },
  {
    provider: 'wistia',
    type: 'video',
    embeddable: true,
    pattern: /^(https?:\/\/)?(.+)?(wistia\.com|wi\.st)\/.*/
    // https://support.wistia.com/medias/26sk4lmiix
  }
]
